<template>
  <div class="details">
    <TopTabbar title="个人资料" text="返回"/>
    <div class="center">
      <div class="avatar">
        <van-uploader 
          v-model="fileList" 
          reupload 
          max-count="1" 
          :deletable="false"
          :before-read="upLoad"
        />
      </div>
      <div class="item" @click="updateAdmin">
        <span>账号</span>
        <span>{{ User.username }}</span>
        <span></span>
      </div>
      <div class="item" @click="updateNickname">
        <span>昵称</span>
        <span>{{ User.nickname}}</span>
        <span></span>
      </div>
      <div class="item" @click="updateSex">
        <span>性别</span>
        <span>{{ User.gender == 0 ? '男' : '女'}}</span>
        <span></span>
      </div>
      <div class="item">
        <span>注册时间</span>
        <span>{{ createtime }}</span>
      </div>
      <div class="item">
        <span>上次登陆</span>
        <span>{{ prevtime }}</span>
      </div>
    </div>
    <van-dialog v-model:show="show" :title="van_dialog_title" show-cancel-button @confirm="update">
      <van-field v-model="value"  placeholder="请输入" />
    </van-dialog>
  </div>
</template>

<script setup>
  import { showToast } from 'vant'
  import { onMounted,reactive,ref,computed } from 'vue'
  import { reqUser,reqProfile,reqUpAvatar } from '../../../API/user'
  import {  time }from '../../../utils/time'
  const User = reactive({})
  const show = ref(false)
  const van_dialog_title = ref('')
  const value = ref('')
  const fileList = ref([
    { url: '' },
  ]);
  const result = ref('');
  const showPicker = ref(false);
  const columns = [
    { text: '男'},
    { text: '女'},
  ]

  onMounted(() => {
    getUser()
  })
  //获取用户信息
  const getUser = async () => {
    const result = await reqUser()
    if(result.code == 1) {
      Object.assign(User,result.data)
      fileList.value[0].url = result.data.avatar
    }
  }

  //上传头像
  const upLoad = async (file) => {
    const formData = new FormData()
    formData.append('file', file)
    const result = await reqUpAvatar(formData)
    if(result.code == 1){
      const result2 = await reqProfile({avatar:result.data.url})
    }
  }

  //修改账号
  const updateAdmin = () => {
    van_dialog_title.value = '修改账号'
    show.value = true
  }

  //修改昵称
  const updateNickname = () => {
    van_dialog_title.value = '修改昵称'
    show.value = true
  }

  //修改性别
  const updateSex = () => {
    van_dialog_title.value = '修改性别'
    show.value = true
  }

  //确认修改
  const update = async () => {
    let result
    if(van_dialog_title.value == '修改账号'){
      result = await reqProfile({username:value.value})
    }else if(van_dialog_title.value == '修改昵称'){
      result = await reqProfile({nickname:value.value})
    }else if(van_dialog_title.value == '修改性别'){
      if(value.value != '男' && value.value != '女'){
        showToast('性别只能为男女')
      }else{
        result = await reqProfile({gender:value.value == '男' ? 0 : 1})
      }
    }
    if(result.code == 1){
      showToast('修改成功')
      getUser()
    }
  }

  //注册时间
  const createtime = computed(() => {
    return time(User.createtime)
  })

  //上次登陆时间
  const prevtime = computed(() => {
    return time(User.prevtime)
  })
</script>

<style lang="scss" scoped>
  .details{
    width: 100%;
    height: 100vh;
    overflow: hidden;
    background-color: #f8f8f8;
    .center{
      width: 100%;
      height: calc(100% - 46px);
      .avatar{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 15px 0;
        background-color: white;
      }
      .item{
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 20px 15px;
        background-color: white;
        font-size: 16px;
        margin-top: 2px;
        span{
          &:nth-of-type(1){
            width: 70px;
          }
          &:nth-of-type(2){
            flex: 1;
          }
          &:nth-of-type(3){
            width: 10px;
            height: 10px;
            border-top: 2px solid #7b7b7b;
            border-right: 2px solid #7b7b7b;
            transform: rotate(45deg);
          }
        }
      }
    }
  }
  ::v-deep(.van-field__control){
    font-size: 16px;
    height: 50px;
    background-color: #f8f8f8;
    padding: 0 10px;
    margin: 10px 0;
  }
</style>