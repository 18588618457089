//时间戳转换函数
export function time(time){
	if(time){
		let d = new Date(time * 1000);
		let yyyy = d.getFullYear() + '-';
		let MM = (d.getMonth()+1 < 10 ? '0'+(d.getMonth()+1) : d.getMonth()+1) + '-';
		let dd = (d.getDate() <10 ? '0'+d.getDate() : d.getDate()) + ' ';
		let HH = (d.getHours() <10 ? '0'+d.getHours() : d.getHours()) + ':';
		let mm = (d.getMinutes() <10 ? '0'+d.getMinutes() : d.getMinutes()) + ':';
		let ss = (d.getSeconds() <10 ? '0'+d.getSeconds() : d.getSeconds());
		return yyyy + MM + dd + HH + mm + ss;
	}else{
		return '无'
	}
}